<template>
    <div class="vue-box">

        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>产品明细</el-breadcrumb-item>
                <el-breadcrumb-item>{{product.title}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{m.name}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="c-panel">
            <el-descriptions :column="1">
                <el-descriptions-item label="*产品名称">{{m.name}}</el-descriptions-item>
                <el-descriptions-item label="*类型">{{m.categoryEnum}}</el-descriptions-item>
                <el-descriptions-item label="*状态">{{m.stateEnum}}</el-descriptions-item>
                <el-descriptions-item label="*负责人">{{m.userName}}</el-descriptions-item>
                <el-descriptions-item label="产品备注" >
                    {{m.info}}
                </el-descriptions-item>

            </el-descriptions>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            one: {},
            srcList: [],
            product: {},
            m: {}
        };
    },
    methods: {
        f5() {
            if(this.m.id){
                this.sa.get("/productDetail/find/" + this.m.id).then(res => {
                    this.m = res.data;
                });
            }
        }
    },
    mounted() {},
    created() {
        this.m = this.sa_admin.params;
        if(this.m.product){
            console.log(this.m);
            this.product = this.m.product;
            
        }else{
            if(this.m.productId){
                this.sa.get("/product/find/"+ this.m.id).then(res=>{
                    this.product = res.data;
                })
                this.m.id = this.m.productDetailId;
            }
        }
        this.f5();
        
        
    }
};
</script>

<style>
.header {
    margin: 20px;
}

.title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 29px;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
    margin-left: 20px;
}
</style>